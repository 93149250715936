import { FC, memo, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FeatureFlags } from 'constants/featureToggles';
import { AccessStatus } from 'enums/application';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { getLoanOfficerFormattedArray } from 'utils/formatting';

import { useGetDocumentsQuery, useGetLoanContactsQuery } from 'services/baseAPI';
import { RootState } from 'store/index';
import { selectCachedApplicationsById } from 'store/selectors/applications';
import { selectCompanyId, selectIsRealtorLoanOfficer } from 'store/selectors/auth';
import { selectIsDesktopView } from 'store/selectors/ui';
import { LoanApplication } from 'types/application';

import DocumentsList, { DocumentsSkeleton } from './components/DocumentsList';
import Feedback, { FeedbackSkeleton } from './components/Feedback';
import NoLongerWorkingTogether, {
  NoLongerWorkingTogetherSkeleton,
} from './components/NoLongerWorkingTogether';
import Notes from './components/Notes';

import FileProgress, { FileProgressSkeleton } from '../FileProgress';
import KeyPeople, { KeyPeopleSkeleton } from '../KeyPeople';

import useDynamicProgressHeight from './hooks/useDynamicProgressHeight';
import { GeneralInfoCard, GeneralInfoDetails, GeneralInfoSkeleton } from './GeneralInfo.styles';

interface GeneralInfoProps {
  application?: LoanApplication;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
}
const GeneralInfo: FC<GeneralInfoProps> = ({
  application: currentApplication,
  isLoading,
  isError,
  isSuccess,
}) => {
  const { id } = useParams();

  const isViewPreApprovalsOnly = useFeatureFlag(FeatureFlags.ViewNewDocumentsTab);
  const isLoanContactsEnabled = useFeatureFlag(
    FeatureFlags.EnableLoanContactsOnPresentationRealtors,
  );

  const companyId = useSelector(selectCompanyId);
  const isRealtorLO = useSelector(selectIsRealtorLoanOfficer);
  const IsDesktop = useSelector(selectIsDesktopView);
  const cachedApplication = useSelector((state: RootState) =>
    selectCachedApplicationsById(state, id as string),
  );

  const application = currentApplication || cachedApplication;

  const withAccess = application?.accessStatus === AccessStatus.Approved;
  const isNoApplication = isSuccess && !application;
  const isViewDocumentsList = isRealtorLO && !isViewPreApprovalsOnly;

  // TODO: switch documents widget to pre-approvals after removing ViewNewDocumentsTab FF
  const { data: documents, isLoading: isLoadingDocs } = useGetDocumentsQuery(
    { loanId: application?.id || '', companyId },
    { skip: !withAccess || !isViewDocumentsList },
  );

  const {
    data: loanContacts,
    isLoading: isLoanContactsLoading,
    isError: isLoanContactsError,
  } = useGetLoanContactsQuery({ applicationId: id }, { skip: !isLoanContactsEnabled });

  const loanContactsData = useMemo(() => {
    const loanOfficerArray = getLoanOfficerFormattedArray(application?.loanOfficer);

    return isLoanContactsEnabled ? loanContacts : loanOfficerArray;
  }, [application?.loanOfficer, isLoanContactsEnabled, loanContacts]);

  // fix bug 22915 - issue 3, when user resize window the text is lost due component unmount in previous version
  const Components = useMemo(() => {
    const array = [
      { Component: Feedback, key: 'feedback', applicationId: application?.applicationId as string },
      {
        Component: Notes,
        key: 'notes',
        loanId: application?.id as string,
        applicationId: application?.applicationId as string,
      },
    ];

    return (IsDesktop ? array : array.reverse()).map(({ Component, key, ...props }) => (
      <Component key={key} {...props} />
    ));
  }, [IsDesktop, application?.applicationId, application?.id]);

  useEffect(() => {
    if (isNoApplication) {
      toast('There is nothing to display', { autoClose: 10_000 });
    }
  }, [application, isNoApplication, isSuccess]);

  const { progressRef, containerRef, observableSizeElementRef } =
    useDynamicProgressHeight(isLoading);

  const isPlug = isError || isNoApplication;

  if (!application) {
    return (
      <GeneralInfoDetails>
        <GeneralInfoSkeleton isPlug={isPlug} />
        <DocumentsSkeleton isPlug={isPlug} />
        <FileProgressSkeleton isPlug={isPlug} />
        {IsDesktop ? (
          <>
            <FeedbackSkeleton isPlug={isPlug} />
            {/*skeleton inside*/}
            <Notes isDetailsError={isPlug} isLoadingDetails={!application} />
          </>
        ) : (
          <>
            <Notes isDetailsError={isPlug} isLoadingDetails={!application} />
            <FeedbackSkeleton isPlug={isPlug} />
          </>
        )}
        <KeyPeopleSkeleton isPlug={isPlug} />
        <NoLongerWorkingTogetherSkeleton />
      </GeneralInfoDetails>
    );
  }

  return (
    <GeneralInfoDetails ref={containerRef}>
      <GeneralInfoCard $isClickable={false} isRealtorLO={isRealtorLO} {...application} />
      {withAccess && (
        <>
          {isLoadingDocs ? (
            <DocumentsSkeleton isPlug={isPlug} />
          ) : (
            <DocumentsList
              application={application}
              documents={documents}
              isViewDocumentsList={isViewDocumentsList}
            />
          )}

          {isLoading ? (
            <FileProgressSkeleton isPlug={isPlug} />
          ) : (
            <FileProgress
              isTbd={application.isTbd}
              processingStopReason={application.loanProcessingStopReason}
              progress={application.progress}
              ref={progressRef}
              status={application.loanStatus}
            />
          )}

          {/* fix bug 22915 - issue 3, when user resize window the text is lost due component unmount in previous version*/}
          {Components}

          {isLoading || isLoanContactsLoading ? (
            <KeyPeopleSkeleton isPlug={isPlug || isLoanContactsError} />
          ) : (
            <div ref={observableSizeElementRef}>
              <KeyPeople loanContacts={loanContactsData} />
            </div>
          )}

          <NoLongerWorkingTogether applicationId={application.applicationId} />
        </>
      )}
    </GeneralInfoDetails>
  );
};

export default memo(GeneralInfo);
