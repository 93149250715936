import { ANONYMOUS_STATUS } from 'constants/application';
import { LoanPurpose, Occupancy, PropertyType, PurchaseProcessType } from 'enums/application';
import { preApprovalMapper } from 'utils/documents';

import { BorrowerModel, LoanApplication, LoanApplicationFromServer } from 'types/application';
import { PreApproval } from 'types/preApproval';

import { GetLoanApplicationsPayload } from './types';

export const createParamsForGetLoanApplications = ({
  includes,
  applicationIds,
  count,
  offset,
  status,
  ...props
}: GetLoanApplicationsPayload) => {
  const isAnonymous = status === ANONYMOUS_STATUS;

  return {
    withLoanOnly: true,
    ...(!isAnonymous ? { lastCompletedMilestoneName: status || undefined } : { statuses: status }),
    forClient: 'Realtor',
    includes,
    ...(applicationIds ? { applicationIds } : { count, offset }),
    ...props,
  };
};

export const loanApplicationMapper = (loanApp: LoanApplicationFromServer): LoanApplication => {
  const subjectProperty = loanApp.application.properties?.find(
    property => property?.type === 'SubjectPropertyModel',
  );
  const primaryBorrower = loanApp.application.borrowers?.find(
    borrower => borrower?.type === 'PrimaryBorrowerModel',
  ) as BorrowerModel;

  return {
    ...loanApp,
    applicationId: loanApp.id.applicationId,
    id: loanApp.loan.id,
    isTbd:
      loanApp.application.loanPurpose?.type === LoanPurpose.Purchase &&
      [
        PurchaseProcessType.SearchingOrShoppingAround,
        PurchaseProcessType.MakingOfferOrSigningPurchaseContract,
      ].includes(loanApp.application.loanPurpose.purchaseType.type),
    lastUpdated: loanApp.modifiedDate,
    accessStatus: loanApp.realtorAccessStatus,
    loanPeriod: loanApp?.rateSnapshot?.program?.loanPeriod,
    interestRate:
      typeof loanApp?.rateSnapshot?.program?.rate === 'string'
        ? +loanApp?.rateSnapshot?.program?.rate
        : undefined,
    programPoints:
      typeof loanApp?.rateSnapshot?.program?.points === 'string'
        ? +loanApp?.rateSnapshot?.program?.points
        : undefined,
    programId: loanApp?.rateSnapshot?.program?.programId,
    lockDate: loanApp?.rateSnapshot?.lockDate,
    lockExpirationDate: loanApp?.rateSnapshot?.lockExpirationDate,
    loanAmount: loanApp?.loanAmount ? +loanApp?.loanAmount : undefined,
    downPayment:
      typeof loanApp.application.loanPurpose?.downPayment === 'string'
        ? +loanApp.application.loanPurpose.downPayment
        : undefined,
    loanPurpose: loanApp.application.loanPurpose?.type,
    primaryBorrower: {
      ...primaryBorrower,
      phone: primaryBorrower?.cellPhone,
      fullName: `${primaryBorrower?.firstName} ${primaryBorrower?.lastName}`,
    },
    subjectProperty: {
      id: subjectProperty?.id as string,
      propertyValue: +(subjectProperty?.value as string),
      loanId: loanApp.loan.id,
      type: subjectProperty?.propertyType?.type as PropertyType,
      numberOfUnits:
        typeof subjectProperty?.propertyType?.numberOfUnits === 'string'
          ? +subjectProperty?.propertyType?.numberOfUnits
          : subjectProperty?.propertyType?.numberOfUnits,
      occupancy: subjectProperty?.occupancy?.type as Occupancy,
      addressId: subjectProperty?.address?.id as string,
      address: {
        city: subjectProperty?.address?.address?.city as string,
        country: subjectProperty?.address?.address?.country?.name as string,
        county: subjectProperty?.address?.address?.county as string,
        state: subjectProperty?.address?.address?.country?.state?.abbreviation as string,
        street: subjectProperty?.address?.address?.street as string,
        unit: subjectProperty?.address?.address?.unit as string,
        zip: subjectProperty?.address?.address?.country?.zip as string,
      },
    },
    loanProcessingStopReason: loanApp?.loanProcessingStopReason,
    preApprovals: (loanApp.preApprovals || [])
      .reduce((acc, preApproval) => {
        if (!preApproval.documentId) {
          return acc;
        }

        acc.push(preApprovalMapper(preApproval));

        return acc;
      }, [] as PreApproval[])
      .sort(
        (a, b) => Date.parse(b.createdDate) - Date.parse(a.createdDate), // Move newer at the beginning
      ),
  };
};
