export const HEADER_HEIGHT = '70px';

export const COMMON_PHONE_NUMBER_FORMAT = '+1 (###) ###-####';

export const ZIP_CODE_LENGTH = 5;

// Application Card Plugs
export const ADDRESS_PLUG = 'TBD PreApproval Somecity, XX 00000';
export const TASKS_NUMBER_PLUG = '0';
export const LAST_UPDATED_PLUG = '00/00/0000';
