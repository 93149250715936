import { styled } from 'styled-components';

import { MediaQueries } from 'enums/ui';

import CustomerCard from 'components/ApplicationCard';
import Skeleton from 'components/Skeleton';

// Masonry Layout
// https://tobiasahlin.com/blog/common-flexbox-patterns/
// https://codepen.io/collection/XPjvPP/
export const GeneralInfoDetails = styled.div`
  display: flex;
  flex-flow: column wrap;
  flex-shrink: 0; // fixes inability to wrap in Safari (iOS 14)
  max-width: 1060px;
  gap: 20px 10px;

  @media (max-width: ${MediaQueries.MobileLandscape}) {
    & > *:not(:last-child) {
    }
  }

  @media (min-width: ${MediaQueries.MobileLandscape}) {
    // for center aligning
    width: 700px;

    & > * {
      &:nth-child(odd) {
        order: 1;
      }

      &:nth-child(even) {
        order: 2;
      }
    }
  }

  @media (min-width: ${MediaQueries.Desktop}) {
    // force new columns
    &::before,
    &::after {
      content: '';
      flex-basis: 100%;
      width: 0;
      order: 2;
    }

    & > * {
      // each first child in row
      &:nth-child(3n + 1) {
        order: 1;
      }

      // each second child in row
      &:nth-child(3n + 2) {
        order: 2;
      }

      // each third child in row
      &:nth-child(3n) {
        order: 3;
      }
    }
  }
`;

export const GeneralInfoCard = styled(CustomerCard)`
  width: 340px;
  height: min-content;
`;

export const GeneralInfoSkeleton = styled(Skeleton)`
  width: 340px;
  height: 361px;
  border-radius: 20px;
`;
