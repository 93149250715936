import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { FeatureFlags } from 'constants/featureToggles';
import useDebounce from 'hooks/useDebounce';
import useFeatureFlag from 'hooks/useFeatureFlag';

import { selectIsDesktopView, selectIsMobileView } from 'store/selectors/ui';

const DYNAMIC_HEIGHT_GAP = 52;
const DELAY_RESIZE_DEBOUNCE = 100;

const useDynamicProgressHeight = (isLoading: boolean) => {
  const isDynamicProgress = useFeatureFlag(FeatureFlags.UseDynamicLoanProgress);
  const IsDesktop = useSelector(selectIsDesktopView);
  const isMobile = useSelector(selectIsMobileView);

  const progressRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const observableSizeElementRef = useRef<HTMLDivElement>(null);

  const [observableHeight, setObservableHeight] = useState(0);

  const onResizeDebounced = useDebounce(setObservableHeight, DELAY_RESIZE_DEBOUNCE);

  useEffect(() => {
    if (isLoading) return;

    const observer = new ResizeObserver(entries => {
      entries.forEach((entry: ResizeObserverEntry) => {
        onResizeDebounced(entry.contentRect.height);
      });
    });

    if (observableSizeElementRef.current) {
      observer.observe(observableSizeElementRef.current);
    }

    return () => {
      observer.disconnect();
      onResizeDebounced.cancel();
    };
  }, [isLoading, onResizeDebounced]);

  useLayoutEffect(() => {
    if (
      isDynamicProgress &&
      !isLoading &&
      progressRef.current &&
      containerRef.current &&
      observableSizeElementRef.current
    ) {
      containerRef.current.style.height = '';

      if (isMobile) return;

      const containerInitialHeight = containerRef.current.getBoundingClientRect().height;

      const precalculatedContainerHeight = containerInitialHeight / 2;

      const dynamicHeight =
        progressRef.current.getBoundingClientRect().height +
        observableSizeElementRef.current.getBoundingClientRect().height +
        DYNAMIC_HEIGHT_GAP;

      const delta = IsDesktop ? 80 : 400;
      const maxHeight = Math.max(precalculatedContainerHeight, dynamicHeight);
      const newHeight = Math.round(
        Math.max(maxHeight, dynamicHeight + (maxHeight - precalculatedContainerHeight)) + delta,
      );

      containerRef.current.style.height = `${newHeight}px`;
    }
  }, [IsDesktop, isDynamicProgress, isMobile, isLoading, observableHeight]);

  return {
    progressRef,
    containerRef,
    observableSizeElementRef,
  };
};

export default useDynamicProgressHeight;
